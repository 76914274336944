import { treatFetchError } from '@api/utils';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/routing';
import {
  addOrderToNewBasket,
  addOrderToCurrentBasket,
  abandonBasket,
  checkoutBasket,
  fetchOrders,
  fetchOrderItems,
  fetchCurrentSession,
  fetchCurrentBasket,
  removeBasketOrders,
  autoLocate,
  quickOrder,
  validateItemLocation,
  confirmValidateItemLocation,
  validateItemQuantity,
  confirmValidateItemQuantity,
  startPickingSession,
  stopPickingSession,
  suspendOrder,
  validateToteComplete,
  completeOrder,
  fetchCurrentRoute,
  pickingSessionState,
} from '@api/routing';
import { CurrentPickingSession, FullBasket, FullOrder, Order, OrderWithItems } from '@models/Order';

export function* fetchOrdersSaga(
  action: ActionType<typeof types.fetchOrders.request>
) {
  const { shallow } = action.payload;

  try {
    const orders: Order[] = yield call(fetchOrders);

    yield put(types.fetchOrders.success({ orders, shallow }));
  } catch (error: any) {
    yield put(
      types.fetchOrders.failure({
        error: treatFetchError(error),
        shallow,
      })
    );
  }
}

export function* fetchOrderItemsSaga(
  action: ActionType<typeof types.fetchOrderItems.request>
) {
  const { orderId } = action.payload;

  try {
    const order: OrderWithItems = yield call(fetchOrderItems, orderId);

    yield put(types.fetchOrderItems.success({ order }));
  } catch (error: any) {
    yield put(
      types.fetchOrderItems.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* addOrderToCurrentBasketSaga(
  action: ActionType<typeof types.addOrderToCurrentBasket.request>
) {
  const { orderId } = action.payload;

  try {
    yield call(addOrderToCurrentBasket, orderId);

    yield put(types.addOrderToCurrentBasket.success());
  } catch (error: any) {
    yield put(
      types.addOrderToCurrentBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* addOrderToNewBasketSaga(
  action: ActionType<typeof types.addOrderToNewBasket.request>
) {
  const { orderId } = action.payload;

  try {
    yield call(addOrderToNewBasket, orderId);

    yield put(types.addOrderToNewBasket.success());
  } catch (error: any) {
    yield put(
      types.addOrderToNewBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* abandonBasketSaga(
  action: ActionType<typeof types.abandonBasket.request>
) {
  const { basketId } = action.payload;

  try {
    yield call(abandonBasket, basketId);

    yield put(types.abandonBasket.success());
  } catch (error: any) {
    yield put(
      types.abandonBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* checkoutBasketSaga(
  action: ActionType<typeof types.checkoutBasket.request>
) {
  const { basketId } = action.payload;

  try {
    yield call(checkoutBasket, basketId);

    yield put(types.checkoutBasket.success());
  } catch (error: any) {
    yield put(
      types.checkoutBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* fetchCurrentSessionSaga() {
  try {
    const basket: CurrentPickingSession | undefined = yield call(fetchCurrentSession);

    yield put(types.fetchCurrentSession.success(basket));
  } catch (error: any) {
    yield put(
      types.fetchCurrentBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* fetchCurrentBasketSaga() {
  try {
    const basket: FullBasket | undefined = yield call(fetchCurrentBasket);

    yield put(types.fetchCurrentBasket.success(basket));
  } catch (error: any) {
    yield put(
      types.fetchCurrentBasket.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* removeBasketOrdersSaga(
  action: ActionType<typeof types.removeBasketOrders.request>
) {
  const { basketId, orderIds } = action.payload;

  try {
    yield call(removeBasketOrders, basketId, orderIds);

    yield put(types.removeBasketOrders.success({ orderIds }));
  } catch (error: any) {
    yield put(
      types.removeBasketOrders.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* autoLocateSaga(
  action: ActionType<typeof types.autoLocate.request>
) {
  const { basketId } = action.payload;

  try {
    yield call(autoLocate, basketId);

    yield put(types.autoLocate.success());
  } catch (error: any) {
    yield put(
      types.autoLocate.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* quickOrderSaga() {
  try {
    const order: FullOrder | undefined = yield call(quickOrder);

    yield put(types.quickOrder.success(order));
  } catch (error: any) {
    yield put(
      types.quickOrder.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* validateItemLocationSaga(
  action: ActionType<typeof types.validateItemLocation.request>
) {
  const { barcode, pickingSessionId } = action.payload;

  try {
    const data: { pickingTaskId: string, locationId: string } = yield call(validateItemLocation, barcode, pickingSessionId);

    yield put(types.validateItemLocation.success({ ...data, barcode }));
  } catch (error: any) {
    yield put(
      types.validateItemLocation.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* confirmValidateItemLocationSaga(
  action: ActionType<typeof types.confirmValidateItemLocation.request>
) {
  const { pickingSessionId, pickingTaskId } = action.payload;

  try {
    yield call(confirmValidateItemLocation, pickingSessionId, pickingTaskId);

    yield put(types.confirmValidateItemLocation.success());
  } catch (error: any) {
    yield put(
      types.confirmValidateItemLocation.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* validateItemQuantitySaga(
  action: ActionType<typeof types.validateItemQuantity.request>
) {
  const { barcode, pickingSessionId, locationId } = action.payload;

  try {
    const data: { itemName: string, requiredQuantity: number } = yield call(validateItemQuantity, barcode, pickingSessionId, locationId);

    yield put(types.validateItemQuantity.success(data));
  } catch (error: any) {
    yield put(
      types.validateItemQuantity.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* confirmValidateItemQuantitySaga(
  action: ActionType<typeof types.confirmValidateItemQuantity.request>
) {
  const { pickingSessionId, pickingTaskId, quantity } = action.payload;

  try {
    yield call(confirmValidateItemQuantity, pickingSessionId, pickingTaskId, quantity);

    yield put(types.confirmValidateItemQuantity.success());
  } catch (error: any) {
    yield put(
      types.confirmValidateItemQuantity.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* startPickingSessionSaga(
  action: ActionType<typeof types.startPickingSession.request>
) {
  const { basketId } = action.payload;

  try {
    const { id } = yield call(startPickingSession, basketId);

    yield put(types.startPickingSession.success({ id }));
  } catch (error: any) {
    yield put(
      types.startPickingSession.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* stopPickingSessionSaga(
  action: ActionType<typeof types.stopPickingSession.request>
) {
  const { pickingSessionId } = action.payload;

  try {
    yield call(stopPickingSession, pickingSessionId);

    yield put(types.stopPickingSession.success());
  } catch (error: any) {
    yield put(
      types.stopPickingSession.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* suspendOrderSaga(
  action: ActionType<typeof types.suspendOrder.request>
) {
  const { pickingSessionId, pickingTaskId } = action.payload;

  try {
    yield call(suspendOrder, pickingSessionId, pickingTaskId);

    yield put(types.suspendOrder.success());
  } catch (error: any) {
    yield put(
      types.suspendOrder.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* validateToteCompleteSaga(
  action: ActionType<typeof types.validateToteComplete.request>
) {
  const { pickingSessionId, pickingTaskId, orderBarcode } = action.payload;

  try {
    yield call(validateToteComplete, pickingSessionId, pickingTaskId, orderBarcode);

    yield put(types.validateToteComplete.success());
  } catch (error: any) {
    yield put(
      types.validateToteComplete.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* completeOrderSaga(
  action: ActionType<typeof types.completeOrder.request>
) {
  const { pickingSessionId, pickingTaskId } = action.payload;

  try {
    yield call(completeOrder, pickingSessionId, pickingTaskId);

    yield put(types.completeOrder.success());
  } catch (error: any) {
    yield put(
      types.completeOrder.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* fetchCurrentRouteSaga(
  action: ActionType<typeof types.fetchCurrentRoute.request>
) {
  const { pickingSessionId } = action.payload;

  try {
    const { currentRoute } = yield call(fetchCurrentRoute, pickingSessionId);

    yield put(types.fetchCurrentRoute.success(currentRoute));
  } catch (error: any) {
    yield put(
      types.fetchCurrentRoute.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export function* pickingSessionStateSaga(
  action: ActionType<typeof types.pickingSessionState.request>
) {
  const { pickingSessionId } = action.payload;

  try {
    const { currentState } = yield call(pickingSessionState, pickingSessionId);

    yield put(types.pickingSessionState.success(currentState));
  } catch (error: any) {
    yield put(
      types.pickingSessionState.failure({
        error: treatFetchError(error),
      })
    );
  }
}
