import axios from './axios';

import { CurrentPickingRoute, CurrentPickingSession, CurrentPickingState, FullBasket, FullOrder, Order, OrderWithItems } from '@models/Order';

export async function fetchOrders(): Promise<Order[]> {
  const { data } = await axios.get('/routing/orders/read-orders');

  return data;
}

export async function fetchOrderItems(orderId: string): Promise<OrderWithItems> {
  const { data } = await axios.post('/routing/actions_orders/get-orders-with-items-and-locations', [orderId]);

  return {
    ...((data[0] || {}).order || {}),
    items: (data[0].items || []).map((i: any) => ({ ...i.item, ...i, item: undefined })),
  };
}

export async function addOrderToNewBasket(orderId: string): Promise<void> {
  await axios.post('/routing/actions_orders/add-orders-to-new-basket', [
    orderId
  ]);
}

export async function addOrderToCurrentBasket(orderId: string): Promise<void> {
  await axios.post('/routing/actions_orders/add-orders-to-current-basket', [
    orderId
  ]);
}

export async function abandonBasket(basketId: string): Promise<void> {
  await axios.post('/routing/actions_orders/abandon-basket', basketId);
}

export async function checkoutBasket(basketId: string): Promise<void> {
  await axios.post('/routing/actions_orders/checkout-basket-and-assign-to-employee', basketId);
}

export async function fetchCurrentSession(): Promise<CurrentPickingSession | undefined> {
  const { data } = await axios.get('/routing/actions_picking/get-current-picking-session');

  return data;
}

export async function fetchCurrentBasket(): Promise<FullBasket | undefined> {
  const { data } = await axios.get('/routing/actions_orders/get-active-basket-detail');

  return {
    ...(data[0] || {}),
    orders: (data[1] || []).map((o: any) => ({ ...o.order, items: (o.items || []).map((i: any) => ({ ...i.item, ...i, item: undefined })) })),
  };
}

export async function removeBasketOrders(basketId: string, orderIDs: string[]): Promise<void> {
  await axios.post(`/routing/actions_orders/remove-orders-from-basket?basket_id=${basketId}`, orderIDs);
}

export async function quickOrder(): Promise<FullOrder> {
  const { data } = await axios.get(`/routing/actions_orders/quick-order`);

  return {
    ...(data.order || {}),
    items: (data.items || []).map((i: any) => ({ ...i.item, ...i, item: undefined })),
  };
}

export async function autoLocate(basketId: string): Promise<void> {
  await axios.post('/routing/actions_locations/auto-locate', [
    basketId
  ]);
}

export async function validateItemLocation(barcode: string, pickingSessionId: string): Promise<{ pickingTaskId: string, locationId: string }> {
  const { data } = await axios.post(`/routing/actions_picking/start-validate-item-location?picking_session_id=${pickingSessionId}&barcode=${barcode}`);

  return { pickingTaskId: ((data || {}).pick_task || {}).id || '', locationId: ((data || {}).location || {}).id || '' };
}

export async function confirmValidateItemLocation(pickingSessionId: string, pickingTaskId: string): Promise<void> {
  await axios.post(`/routing/actions_picking/confirm-validate-item-location?picking_session_id=${pickingSessionId}&pick_task_id=${pickingTaskId}`);
}

export async function validateItemQuantity(barcode: string, pickingSessionId: string, locationId: string): Promise<{ itemName: string, requiredQuantity: number }> {
  const { data } = await axios.post(`/routing/actions_picking/start-validate-item-quantity?picking_session_id=${pickingSessionId}&barcode=${barcode}&location_id=${locationId}`);

  return { itemName: (data || {}).item_name || '', requiredQuantity: (data || {}).required_quantity || '' };
}

export async function confirmValidateItemQuantity(pickingSessionId: string, pickingTaskId: string, quantity: number): Promise<void> {
  await axios.post(`/routing/actions_picking/confirm-validate-item-quantity?picking_session_id=${pickingSessionId}&pick_task_id=${pickingTaskId}&confirmed_quantity=${quantity}`);
}

export async function startPickingSession(basketId: string): Promise<{ id: string }> {
  const { data } = await axios.post(`/routing/actions_picking/start-picking-session?basket_id=${basketId}&picking_mode=manual`, {});

  return data;
}

export async function stopPickingSession(pickingSessionId: string): Promise<void> {
  await axios.get(`/routing/actions_picking/end-picking-session?picking_session_id=${pickingSessionId}`);
}

export async function suspendOrder(pickingSessionId: string, pickingTaskId: string): Promise<void> {
  await axios.post(`/routing/actions_picking/suspend-order?picking_session_id=${pickingSessionId}&picking_task_id=${pickingTaskId}`);
}

export async function validateToteComplete(pickingSessionId: string, pickingTaskId: string, orderBarcode: string): Promise<void> {
  await axios.post(`/routing/actions_picking/validate-tote-complete-order?picking_session_id=${pickingSessionId}&picking_task_id=${pickingTaskId}&order_barcode=${orderBarcode}`);
}

export async function completeOrder(pickingSessionId: string, pickingTaskId: string): Promise<void> {
  await axios.post(`/routing/actions_picking/complete-order?picking_session_id=${pickingSessionId}&picking_task_id=${pickingTaskId}`);
}

export async function pickingSessionState(pickingSessionId: string): Promise<{ currentState: CurrentPickingState }> {
  const { data } = await axios.get(`/routing/actions_picking/get-picking-session-state?picking_session_id=${pickingSessionId}`);

  return {
    currentState: {
      pickingSessionId: (data || {}).session_id || '',
      tasks: (((data || {}).route || {}).tasks || []).map((t: any) => ({
        pickingTaskId: t.task_id,
        type: t.type,
        itemId: t.item_id,
        itemName: t.item_name,
        locationId: t.location_id,
        locationBarcode: t.location_barcode,
        requiredQuantity: t.required_quantity,
        pickedQuantity: t.picked_quantity,
        isComplete: !!(t.status || {}).completed_date,
      })),
      route: (((data || {}).route || {}).route || []).map((t: any) => ({
        pickingTaskId: t.picking_task_id,
        sequenceOrder: t.sequence_order,
        itemId: t.item_id,
        itemName: t.item_name,
        locationCode: t.location_code,
        quantityToPick: t.quantity_to_pick,
        quantityPicked: t.quantity_picked,
        locationX: t.location_x,
        locationY: t.location_y,
        locationZ: t.location_z,
        isComplete: t.is_complete,
        isDepo: t.is_depot,
      })),
    },
  };
}

export async function fetchCurrentRoute(pickingSessionId: string): Promise<{ currentRoute: CurrentPickingRoute }> {
  const { data } = await axios.get(`/routing/actions_picking/get-current-route-for-picking-session?picking_session_id=${pickingSessionId}`);

  return {
    currentRoute: {
      pickingSessionId: ((data || {}).picking_session_id || {}).id || '',
      tasks: ((data || {}).tasks || []).map((t: any) => ({
        pickingTaskId: t.picking_task_id,
        sequenceOrder: t.sequence_order,
        itemId: t.item_id,
        itemName: t.item_name,
        locationX: t.location_x,
        locationY: t.location_y,
        locationZ: t.location_z,
        isComplete: t.is_complete,
        isDepo: t.is_depot,
      }))
    },
  };
}
